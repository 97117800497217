<template>
  <div>
    <p>
      By clicking “ACCEPT” below, you hereby agree on behalf of your
      organization (the “End User”) to the following terms and conditions:
    </p>

    <ol id="eula" class="list-lower-alpha rfs-m-6-l rfs-m-5-b">
      <li>
        Licensed Content is copyrighted by the American Medical Association and
        CPT is a registered trademark of the AMA.
      </li>
      <li>
        Agilum Healthcare Intelligence, Inc. (“Agilum”), as a party to a license
        agreement with the AMA, is authorized to grant End User a limited,
        non-exclusive, non-transferable, non-sublicensable license for End User
        to use Licensed Content in Agilum’s Licensed Product(s), for the sole
        purpose of internal use by End User within the Territory. The sublicense
        granted hereunder shall automatically terminate upon termination of the
        Agreement between Agilum and AMA, unless prior written consent of AMA is
        obtained by Agilum or a direct license between End User and AMA is
        entered.
      </li>
      <li>
        The provision of updated Licensed Content in the Licensed Product(s) is
        dependent on a continuing contractual relationship between Agilum and
        the AMA.
      </li>
      <li>
        End User is prohibited from making Licensed Content publicly available,
        creating derivative works (including translating), transferring,
        selling, leasing, licensing, or otherwise making available to any
        unauthorized party the Licensed Product(s), or a copy or portion of
        Licensed Content to any unauthorized party, including a subsidiary,
        affiliate, or other legal entity, however designated, for any purpose
        whatsoever except as expressly permitted in this Agreement.
      </li>
      <li>
        <strong
          >End User expressly acknowledges and agrees to the extent permitted by
          applicable law, use of the Licensed Content is at End User’s sole risk
          and the Licensed Content is provided “as is” without warranty of any
          kind. The AMA does not directly or indirectly practice medicine or
          dispense medical services. Fee schedules, relative value units,
          conversion factors and/or related components are not assigned by the
          AMA, are not part of CPT, and the AMA is not recommending their use.
          The Licensed Content does not replace the AMA’s Current Procedural
          Terminology book or other appropriate coding authority. The coding
          information contained in the Licensed Content should be used only as a
          guide.</strong
        >
      </li>
      <li>
        Notwithstanding anything to the contrary, End User expressly
        acknowledges and agrees that neither Agilum nor any of its affiliates
        are responsible for the accuracy or completeness of the CPT or any other
        Licensed Content.
      </li>
      <li>
        End User is required to keep records and submit reports including
        information necessary for the calculation of royalties payable to the
        AMA by the Agilum, of the same type as required of Agilum under this
        Agreement. All records and reports required under this Section shall be
        subject to audit by AMA.
      </li>
      <li>
        The following <em>U.S. Government End Users</em> notice shall be
        included:
        <ul class="rfs-m-5-t rfs-m-5-b rfs-m-10-l rfs-m-10-r">
          <li>
            <strong>U.S. Government End Users</strong>. CPT is commercial
            technical data, which was developed exclusively at private expense
            by the American Medical Association (AMA), 330 North Wabash Avenue,
            Chicago, Illinois 60611. This agreement does not grant the Federal
            Government a direct license to use CPT based on FAR 52.227-14 (Data
            Rights - General) and DFARS 252.227-7015 (Technical Data -
            Commercial Items).
          </li>
        </ul>
      </li>
      <li>
        End User must ensure that anyone with authorized access to the Licensed
        Product(s) will comply with the provisions of the End User Agreement.
      </li>
      <li>
        AMA is hereby named as a third-party beneficiary of the End User
        Agreement.
      </li>
      <li>
        End User expressly consents to the release of its name to the AMA.
      </li>
    </ol>

    <button class="btn btn-primary" @click="accept">Accept</button>
    <button class="btn btn-primary rfs-m-2-l" @click="reject">Reject</button>
  </div>
</template>

<script>
  export default {
    name: 'EULA',
    methods: {
      accept() {
        this.$store.dispatch('auth/acceptEula').then(() => {
          this.$router.push({ name: 'Welcome' });
        });
      },
      reject() {
        this.$store.dispatch('auth/logout').then(() => {
          this.$router.push({ name: 'Login' });
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  #eula > li {
    padding-bottom: 10px;
  }

  #eula em {
    font-style: italic;
  }
</style>
