<template>
  <div>
    <div id="landingPageSideBar" style="width:700px;">
      <h2 class="fs-20 fw-500">Welcome!</h2>
      <!-- Hospital Multi-select component -->
      <multi-select-checkbox-dropdown
        v-model="hospitalSegment"
        :options="hospitalOptions"
        optionLabel="text"
        fieldLabel="Hospital"
        :clearable="false"
        class="form-group form-row"
        @searchedOptions="updateSearchOptions"
      />
      <br/>
      <div class="product-launch">
        <router-link
          v-if="$can('access', 'variance-reports')"
          :class="launchButtonClasses"
          data-landing-var-rep
          to="/variance-reports"
        >
          Explore Variance Reports
          <font-awesome-icon class="rfs-22" icon="sign-in-alt" />
        </router-link>
        <router-link
          v-if="$can('access', 'population-builder-rx')"
          :class="launchButtonClasses"
          data-landing-pop-builder
          to="/pop-builder-rx"
        >
          Explore <pop-builder-formatted tm />
          <font-awesome-icon class="rfs-22" icon="sign-in-alt" />
        </router-link>
        <div
          v-if="
            !$can('access', 'variance-reports') &&
            !$can('access', 'population-builder-rx')
          "
        >
          No Product Permissions
        </div>
      </div>
      <br /><br />
    </div>
  </div>
</template>

<script>
  import PopBuilderFormatted from '@/components/branding/PopBuilderFormatted.vue';
  import multiSelectCheckboxDropdown from '@/components/base/forms/multi_select_checkbox_dropdown';
  export default {
    name: 'Welcome',
    components: {
      PopBuilderFormatted,
      multiSelectCheckboxDropdown
    },
    data() {
      return {
        launchButtonClasses: 'btn btn-secondary btn-lg rfs-m-8-t',
        hospitalOptions: [],
        selectedOptions: []
      };
    },
    computed: {
      productTitle() {
        return this.$route.meta.productTitle;
      },
      hospitalSegment: {
        get() {
          return this.selectedOptions;
        },
        set(value) {
          this.$store.commit('auth/setSelectedHospitalList', value);
          this.selectedOptions = value;
        }
      }
    },
    methods: {
      updateSearchOptions(v) {
        this.$store.commit('auth/setHospitalSearchOption', v);
      }
    },
    created() {
      let hospitals = this.$store.getters['auth/userHospitalList'];
      let options = [];
      for (let i in hospitals) {
        options.push({ text: hospitals[i].name, value: hospitals[i].id });
      }
      // 1st option as selected
      let selectedHospitalList = this.$store.getters['auth/getSelectedHospitalList'];
      if (selectedHospitalList !== undefined && selectedHospitalList !== null && selectedHospitalList.length > 0) {
        this.selectedOptions = selectedHospitalList;
      } else {
        this.selectedOptions.push(options[0]);
      }

      this.hospitalOptions = options;
    }
  };
</script>

<style lang="scss">
  .product-launch {
    a {
      width: 100%;
    }
    svg {
      vertical-align: middle;
      margin-top: -3px;
    }
  }
</style>
