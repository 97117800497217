<template>
  <span class="uppercase"
    >POP-Builder R<span class="lowercase">X</span
    ><span v-if="tm">&trade;</span></span
  >
</template>

<script>
  export default {
    name: 'PopBuilderFormatted',
    props: {
      tm: Boolean
    }
  };
</script>
